import Joi from '@hapi/joi'
import i18n from 'components/i18n'
import { REFFERAL_ALLOWED_PLANS } from 'components/services/billing_services/plans'
import { assertNever } from 'lib/utils'
import { BillingService } from './billing_service'
import { CompanyBasic, CompanyEngagementSummary, CompanyInvitedUsersCount, CompanySubscriptions, CompanyTags, getCompanyBasicFragment, getCompanyBasicFragmentName, getOrganizationCompanyFragment, getOrganizationCompanyFragmentName } from './company'
import { EngagementSummary } from './engagement_summary'
import { UserInvited, getUserInvitedFragment, getUserInvitedFragmentName } from './user'

export function dispatchOrganizationDeletedEvent(organizationId: string) {
  const event = new CustomEvent('organizationDeleted', {detail: {organizationId}})
  document.dispatchEvent(event)
}
export function listenOrganizationDeletedEvent(f: (organizationId: string) => void) {
  const l: EventListener = (e: Event) => {
    const evt = e as CustomEvent
    if (!evt.detail?.organizationId) return
    f(evt.detail.organizationId)
  }
  document.addEventListener('organizationDeleted', l)
  return {
    remove: () => {
      document.removeEventListener('organizationDeleted', l)
    },
  }
}

export type OrganizationFeatureType = 'assessments'|'manualReviews'|'messaging'|'scorm'|'externalUserAttributes'|'shop'|'explore'|'pptImport'|'integrations'|'certificate'|'hideMarketing'|'whiteLabel'|'sso'|'duplicateAndTranslateCourse'|'botAi'|'generateTextAi'|'improveTextAi'|'generateImageAi'|'disableEnableUserMetric'|'landingPagesEnabled'|'landingPagesTemplates'|'landingPagesWhiteLabeling'|'landingPagesWorkspace'
export const ORGANIZATION_FEATURES: OrganizationFeatureType[] = ['assessments', 'manualReviews', 'messaging', 'scorm', 'externalUserAttributes', 'shop', 'explore', 'pptImport', 'integrations', 'certificate', 'hideMarketing', 'whiteLabel', 'sso', 'duplicateAndTranslateCourse', 'botAi', 'generateTextAi',  'improveTextAi', 'generateImageAi', 'disableEnableUserMetric', 'landingPagesEnabled', 'landingPagesTemplates', 'landingPagesWhiteLabeling', 'landingPagesWorkspace']

export function getOrganizationFeatureLabel(feature: OrganizationFeatureType) {
  if (feature === 'assessments') return 'Assessments (Basic)'
  if (feature === 'manualReviews') return 'Assessment (Pro)'
  if (feature === 'scorm') return 'Scorm'
  if (feature === 'externalUserAttributes') return 'Custom User Attributes'
  if (feature === 'shop') return 'GnowbeShop™️'
  if (feature === 'explore') return 'GnowbeLearn™️ Subscription (Explore tab)'
  if (feature === 'pptImport') return 'PowerPoint import'
  if (feature === 'integrations') return 'Integrations'
  if (feature === 'messaging') return 'Messaging'
  if (feature === 'certificate') return 'Certificate'
  if (feature === 'hideMarketing') return 'Hide Marketing'
  if (feature === 'whiteLabel') return 'Customization'
  if (feature === 'sso') return 'Single Sign On'
  if (feature === 'duplicateAndTranslateCourse') return 'Duplicate and Translate Course'
  if (feature === 'botAi') return 'Bot AI'
  if (feature === 'generateTextAi') return 'Generate Text AI'
  if (feature === 'improveTextAi') return 'Improve Text AI'
  if (feature === 'generateImageAi') return 'Generate Image AI'
  if (feature === 'disableEnableUserMetric') return 'Show Disabled Enabled User Metric'
  if (feature === 'landingPagesEnabled') return 'Landing Pages'
  if (feature === 'landingPagesTemplates') return 'Landing Pages Templates'
  if (feature === 'landingPagesWhiteLabeling') return 'Landing Pages White Labeling'
  if (feature === 'landingPagesWorkspace') return 'Landing Pages (Workspace)'
  assertNever(feature)
}

export function getOrganizationFeatureDesc(feature: OrganizationFeatureType) {
  if (feature === 'assessments') return ''
  if (feature === 'manualReviews') return ''
  if (feature === 'scorm') return ''
  if (feature === 'externalUserAttributes') return ''
  if (feature === 'shop') return ''
  if (feature === 'explore') return 'Enable this feature to show Explore tab to users of this workspace'
  if (feature === 'pptImport') return 'Import PowerPoint slides and automatically create content from them'
  if (feature === 'integrations') return 'Public API, Zapier, ...'
  if (feature === 'messaging') return 'Send Push or Email notification'
  if (feature === 'certificate') return 'Generate certificate for users'
  if (feature === 'hideMarketing') return ''
  if (feature === 'whiteLabel') return ''
  if (feature === 'sso') return ''
  if (feature === 'duplicateAndTranslateCourse') return ''
  if (feature === 'botAi') return ''
  if (feature === 'generateTextAi') return ''
  if (feature === 'improveTextAi') return `${i18n.t('improve_text_ai_desc')}`
  if (feature === 'generateImageAi') return ''
  if (feature === 'disableEnableUserMetric') return ''
  if (feature === 'landingPagesEnabled') return 'Enable this feature to show Group Landing Pages settings (General)'
  if (feature === 'landingPagesTemplates') return 'Enable this feature to show Landing Pages Templates settings'
  if (feature === 'landingPagesWhiteLabeling') return 'Enable this feature to show Landing Pages White Labeling settings'
  if (feature === 'landingPagesWorkspace') return 'Enable this feature to show Workspace Landing Pages settings'
  assertNever(feature)
}

export type OrganizationProductType = 'FT'|'CR'|'UL'|'XL'|'VL'|'CL'|'GT'|'OP'|'IN'|'DM'
export const ORGANIZATION_PRODUCTS = ['FT', 'CR', 'UL', 'XL', 'VL', 'CL', 'GT', 'OP', 'IN', 'DM']
export enum OrganizationProduct {
  FT = 'FT',
  CR = 'CR',
  UL = 'UL',
  XL = 'XL',
  VL = 'VL',
  CL = 'CL',
  GT = 'GT',
  OP = 'OP',
  IN = 'IN',
  DM = 'DM',
}

export const getOrganizationProductDescription = (product: OrganizationProduct) => {
  if (product === OrganizationProduct.FT) return 'Free Tier (FT)'
  if (product === OrganizationProduct.CR) return 'Creator (CR)'
  if (product === OrganizationProduct.UL) return 'Enterprise (UL)'
  if (product === OrganizationProduct.XL) return 'Extend (XL)'
  if (product === OrganizationProduct.VL) return 'Extend (VL)'
  if (product === OrganizationProduct.CL) return 'Learn for Teams (CL)'
  if (product === OrganizationProduct.GT) return 'Learn for Teams (GT)'
  if (product === OrganizationProduct.OP) return 'Learn Public (OP)'
  if (product === OrganizationProduct.IN) return 'Internal (IN)'
  if (product === OrganizationProduct.DM) return 'Demo (DM)'
  assertNever(product)
}

export type OrganizationCompanies = {
  organizationId: string;
  companies: (CompanyBasic&CompanyTags&CompanySubscriptions&CompanyInvitedUsersCount)[];
  engagementSummaryOverall: EngagementSummary;
  engagementsSummary: CompanyEngagementSummary[];
}

export const getOrganizationCompaniesFragmentName = () => 'OrganizationCompaniesField'
export const getOrganizationCompaniesFragment = () => `
  ${getOrganizationCompanyFragment()}
  fragment ${getOrganizationCompaniesFragmentName()} on OrganizationCompaniesRes {
    organizationId
    companies{
      ...${getOrganizationCompanyFragmentName()}
    }
    companiesCount
    companiesNonPublicCount
  }
`
export type OrganizationBasic = {
  id: string;
  name: string;
  product: OrganizationProduct;
  isPilot: boolean;
  clientCode?: string;
  countryCode?: string;
  disabled: boolean;
  createdBy?: string;
  createdAt: number;
  language?: string;
}
export type OrganizationContact = {
  contact: string;
  contactEmail: string;
  gnowbeContactEmail: string;
}
export type OrganizationTags = {
  tags: string[];
}
export type OrganizationSettings = {
  certificateSigner?: string;
  membersListEnabledDefault: boolean;
  wallDisabledDefault: boolean;
  allowUserAnonymity?: ('group_members_list'|'group_leaderboards')[];
  logoUrl: string;
  certSealUrl: string;
  emailFromName: string;
  emailReplyTo: string;
  gnowbefyForAll: boolean;
  allowCustomPolicies: boolean;
  trustedDomains: string[];
  customPalette?: {
    dashboardLogo: string|null;
    appBarBackgroundColor: string|null;
    appBarColor: string|null;
    primaryColor: string|null;
    secondaryColor: string|null;
  };
  policies: string[];
  billingService?: BillingService;
  peerInvitationModeDefault?: 'open'|'approval'|'closed';
  employeeIdRequiredDefault: boolean;
  employeeIdName: string;
  featureFlags: OrganizationFeatureType[];
  shopRevenueFee: number;
  usedLicenses?: number;
  whitelabeling?: {
    largeLogoUrl: string|null|undefined,
    colors: {
      backgroundColor: string|null|undefined,
      textColor: string|null|undefined,
      primaryColor: string|null|undefined,
      accentColor: string|null|undefined,
    }|null|undefined,
  }|null|undefined,
}

export type CourseOrganizationToggledRes = {
  addRemove: 'add'|'remove',
  courseId: string,
  organizationId: string,
  organization: OrganizationBasic,
}

export type OrganizationUserInvited = {
  user: UserInvited,
  companyIds: string[],
  companies: CompanyBasic[],
}

export type OrganizationInvitedUsersRes = {
  usersCount: number,
  users: OrganizationUserInvited[],
}
export type Organization = OrganizationBasic
  &OrganizationContact
  &OrganizationTags
  &OrganizationSettings
  &OrganizationExternalUserAttributes

export type ExternalUserAttributeType = 'email'|'employeeId'|'firstName'|'lastName'|'other'

export const getExternalUserAttributeTypeLocalized = (key: ExternalUserAttributeType) => {
  switch (key) {
    case 'email': return i18n.t('email')
    case 'employeeId': return i18n.t('employee_id')
    case 'other': return i18n.t('other')
    case 'firstName': return i18n.t('first_name')
    case 'lastName': return i18n.t('last_name')
  }
}

export type ExternalUserAttributeValueType = 'string'|'number'

export type ExternalUserAttribute = {
  name: string,
  isPrimaryKey: boolean,
  type: ExternalUserAttributeType,
  valueType: ExternalUserAttributeValueType,
}

export type OrganizationExternalUserAttributes = {
  externalUserAttributes: ExternalUserAttribute[],
  externalUsersLastChangedAt: number,
}

export type OrganizationResponseType = 'with_contact'|'with_tags'|'with_settings'|'with_externalUserAttributes'|'with_styles'

export const getOrganizationFragmentName = (types: OrganizationResponseType[]) => {
  return `OrganizationFields${types.sort().map(t => `_${t}`).join('')}`
}
export const getOrganizationFragment = (types: OrganizationResponseType[]) => {
  // tslint:disable-next-line: prefer-template
  return `
    fragment ${getOrganizationFragmentName(types)} on Organization {
      id` +
      (types.indexOf('with_styles') >= 0 ? `
      customPalette {
        dashboardLogo
        appBarBackgroundColor
        appBarColor
        primaryColor
        secondaryColor
      }` : '') + `
      name
      product
      isPilot
      clientCode
      countryCode
      disabled
      createdBy
      createdAt` +
      (types.indexOf('with_tags') >= 0 ? `
      tags` : '') +
      (types.indexOf('with_contact') >= 0 ? `
      contact
      contactEmail
      gnowbeContactEmail` : '') +
      (types.indexOf('with_settings') >= 0 ? `
      certificateSigner
      membersListEnabledDefault
      wallDisabledDefault
      allowUserAnonymity
      logoUrl
      certSealUrl
      emailFromName
      emailReplyTo
      gnowbefyForAll
      allowCustomPolicies
      trustedDomains
      policies
      billingService {
        type
        subscriptionId
        purchasedLicenses
        pricingType
        maxGroups
        maxLearners
        maxTranslatedWords
        maxGnowbeAi
        planName
        planId
      }
      whitelabeling {
        largeLogoUrl
        colors {
          backgroundColor
          textColor
          primaryColor
          accentColor
        }
      }
      featureFlags
      shopRevenueFee
      usedLicenses` : '') + `
      language
      peerInvitationModeDefault
      employeeIdRequiredDefault
      employeeIdName` +
      (types.indexOf('with_externalUserAttributes') >= 0 ? `
      externalUserAttributes {
        name
        isPrimaryKey
        type
        valueType
      }
      externalUsersLastChangedAt` : '') + `
    }
  `
}

export type OrganizationUpdate = {
  name?: string;
  contact?: string;
  contactEmail?: string;
  gnowbeContactEmail?: string;
  certificateSigner?: string;
  logoUrl?: string;
  customPalette?: {
    dashboardLogo?: string;
    appBarBackgroundColor?: string;
    appBarColor?: string;
    primaryColor?: string;
    secondaryColor?: string;
  };
  allowCustomPolicies?: boolean;
  trustedDomains?: string[];
  certSealUrl?: string;
  emailFromName?: string;
  emailReplyTo?: string;
  gnowbefyForAll?: boolean;
  tags?: string[];
  clientCode?: string;
  countryCode?: string;
  product?: string;
  membersListEnabledDefault?: boolean;
  wallDisabledDefault?: boolean;
  allowUserAnonymity?: string[];
  isPilot?: boolean;
  disabled?: boolean;
  language?: string;
  peerInvitationModeDefault?: 'open'|'approval'|'closed';
  employeeIdRequiredDefault?: boolean;
  employeeIdName?: string;
  featureFlags?: OrganizationFeatureType[];
  shopRevenueFee?: number;
  whitelabeling?: {
    largeLogoUrl: string|null|undefined,
    colors: {
      backgroundColor: string|null|undefined,
      textColor: string|null|undefined,
      primaryColor: string|null|undefined,
      accentColor: string|null|undefined,
    }|null|undefined,
  }|null|undefined,
}
export const OrganizationUpdate = Joi.object().keys({
  name: Joi.string().label('Name').min(3).regex(/[^\.]$|^$/i).allow('', null),
  contact: Joi.string().label('Contact').allow('', null),
  contactEmail: Joi.string().label('Contact eMail').allow('', null),
  gnowbeContactEmail: Joi.string().label('Gnowbe Contact eMail').allow('', null),
  certificateSigner: Joi.string().label('Certificate signer')
    .regex(/^$|^(([^;]+;){2}([^;\n\r]+))+[\n\r]*$/i).allow('', null),
  logoUrl: Joi.string().label('Logo').allow('', null),
  customPalette: Joi.object().keys({
    dashboardLogo: Joi.string().label('Custom Dashboard logo').allow('', null),
    appBarBackgroundColor: Joi.string().max(7).optional().allow(null),
    appBarColor: Joi.string().optional().max(7).allow(null),
    primaryColor: Joi.string().optional().max(7).allow(null),
    secondaryColor: Joi.string().optional().max(7).allow(null),
  }).optional().allow(null),
  allowCustomPolicies: Joi.boolean().label('Allow custom policies'),
  trustedDomains: Joi.array().items(Joi.string()),
  certSealUrl: Joi.string().label('Seal').allow('', null),
  emailFromName: Joi.string().label('Email from name').max(50).allow('', null),
  emailReplyTo: Joi.string().max(200).email({minDomainSegments: 2, tlds: { allow: false }})
    .label('Email reply to').allow('', null),
  gnowbefyForAll: Joi.boolean(),
  tags: Joi.array().label('Tags').items(Joi.string().label('Tag')).allow(null),
  clientCode: Joi.string().alphanum().max(20).label('Client Code').allow('', null),
  countryCode: Joi.string().label('Country Code').allow('', null),
  product: Joi.string().label('Product').allow('', null),
  membersListEnabledDefault: Joi.boolean().allow(null),
  wallDisabledDefault: Joi.boolean().allow(null),
  allowUserAnonymity: Joi.array().items(Joi.string()).allow(null),
  isPilot: Joi.boolean().allow(null),
  disabled: Joi.boolean().label('Disabled').allow(null),
  language: Joi.string().label('Language').allow('', null),
  peerInvitationModeDefault: Joi.string().label('Default invitation mode').allow('', null),
  employeeIdRequiredDefault: Joi.boolean().allow(null),
  employeeIdName: Joi.string().allow(null, ''),
  featureFlags: Joi.array().allow(null),
  shopRevenueFee: Joi.number().allow(null),
  whitelabeling: Joi.object().keys({
    largeLogoUrl: Joi.string().optional().allow(null),
    colors: Joi.object().keys({
      backgroundColor: Joi.string().optional().allow(null),
      textColor: Joi.string().optional().allow(null),
      primaryColor: Joi.string().optional().allow(null),
      accentColor: Joi.string().optional().allow(null),
    }).optional().allow(null),
  }).optional().allow(null),
})

export const getOrganizationInvitedUsersFragmentName = () => {
  return 'OrganizationInvitedUsersFields'
}
export const getOrganizationInvitedUsersFragment = () => {
  return `
    ${getUserInvitedFragment()}
    ${getCompanyBasicFragment()}
    fragment ${getOrganizationInvitedUsersFragmentName()} on OrganizationUserInvited {
      user {
        ...${getUserInvitedFragmentName()}
      }
      companyIds
      companies {
        ...${getCompanyBasicFragmentName()}
      }
    }
  `
}

export function orgHasFeatureFlag(org: Partial<Pick<OrganizationSettings, 'featureFlags'>>, feature: OrganizationFeatureType) {
  return org.featureFlags?.includes(feature) || false
}
export function orgHasSomeFeatureFlag(org: Partial<Pick<OrganizationSettings, 'featureFlags'>>, features: OrganizationFeatureType[]) {
  return features.some(f => orgHasFeatureFlag(org, f))
}
export const isFreetier = (product: OrganizationProduct) => {
  return product === OrganizationProduct.FT
}
export const isCreator = (product: OrganizationProduct) => {
  return product === OrganizationProduct.CR
}
export const isFreetierOrCreator = (product: OrganizationProduct) => {
  return isFreetier(product) || isCreator(product)
}
export const isStarterOrProPlan = (billingService?: Pick<BillingService, 'planId'>) => {
  return billingService?.planId && REFFERAL_ALLOWED_PLANS.includes(billingService.planId)
}

export type OrganizationSsoSettings = {
  businessId: string,
  businessIdFriendly: string,
  emailDomains: string[],
  organizationId: string,
}

export type OrganizaitonLicenseLimits = {
  billingService?: BillingService,
  usedGroups: number,
  usedLearners: number,
  usedLicenses: number,
}

export const getOrganizationAddonsFragmentName = () => {
  return `AddonsLimitFields`
}
export const getOrganizationAddonsFragment = () => {
  // tslint:disable-next-line: prefer-template
  return `
    fragment ${getOrganizationAddonsFragmentName()} on AddonLimitPerOrganizationRes {
      addon
      billingCycle
      count
      countUnit
      description
      feature
      freeCredits
      freeCreditsLeft
      limit
      price
      status
      step
      stepMax
      stepMin
      title
      usagePerMonths {
        month
        totalUsageCount
      }
    }
  `
}

export type AddonCountUnit = 'words'|'credits'
export type OrganizationAddonsLimit = {
  addon: string,
  billingCycle: string,
  count: number,
  countUnit: string,
  description: string,
  feature: string,
  // flag: string,
  freeCredits: number,
  freeCreditsLeft: number,
  limit: number,
  price: number,
  status: string,
  step: number,
  stepMax: number,
  stepMin: number,
  title: string,
  usagePerMonths?: {
    month: string,
    totalUsageCount: number,
  }[],
}
